import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],
    state: {
        key: ''
    },
    getters: {
        getKey: state => {
            return state.key
        }
    },
    mutations: {
        setKey(state, key) {
            state.key = key
        }
    },
    actions: {
    },
    modules: {
    }
})
